import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  styleUrls: [],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'family-portal';
  private errorSubscription: Subscription;
  errorMessage: string | null = null;

  constructor (public authService: AuthService) {}

  ngOnInit (): void {
    this.errorSubscription = this.authService.error$.subscribe(
      error => {
        console.error('Authentication error:', error);
      },
    );
  }

  ngOnDestroy (): void {
    this.errorSubscription.unsubscribe();
  }
}
