import { Component, Inject, Renderer2, ViewEncapsulation } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { FamilyPortalConfig } from 'src/app/services/family-portal-config';
import { WindowRef } from '../../../../../shared/services/window-ref/windowRef';
import { BehaviorSubject, Subject } from 'rxjs';
import { ILinkAccessibilityContent } from '../../../../../shared/components/skip-link/skip-link.interface';
import { INavBarAccessibilityContent, INavBarConfigContent } from '../../../../../shared/layouts/responsive-nav-bar/nav-bar.interface';
import { SUPPORTED_LANGUAGES } from '../../../../../shared/constants/supported-languages';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '../../../app/services/translate/translate.service';
import { takeUntil, tap } from 'rxjs/operators';
import { defaultNavBarContent } from '../../constants/nav-bar.config';
import { NavbarConfigService } from '../../../../../shared/layouts/responsive-nav-bar/nav-bar.config.service';
import { ISidebarItem } from '../../../../../shared/layouts/responsive-nav-bar/sidebar-list/sidebar.interface';

@Component({
  selector: 'family-portal-layout-page',
  templateUrl: './family-portal-layout-page.component.html',
  styleUrls: ['./family-portal-layout-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FamilyPortalLayoutPageComponent {
  public headerProps = {
    applicationName: 'Family Portal: Every Child and Family is Known',
    logOutLabel: 'Log out',
    profileLabel: 'Profile',
    profileUrl: this.familyPortalConfig.publicConfig.NYC_ID_PROFILE_URL,
    headerImage: {
      src: 'https://www.nyc.gov/assets/nyc4d/images/pages/intergration/nyc_white@x2.png',
      alt: 'white NYC logo',
    },
  };

  public langSwitcherProps: {
    selectedLang: string;
    languages: { key: string; human: string }[];
  };

  public translatedLinkAccessibility$: BehaviorSubject<ILinkAccessibilityContent> = new BehaviorSubject<ILinkAccessibilityContent>(null);
  public translatedNavBarAccessibility$: BehaviorSubject<INavBarAccessibilityContent> = new BehaviorSubject<INavBarAccessibilityContent>(null);
  public translatedNavBarConfig$: BehaviorSubject<{ [key:string]: ISidebarItem[] }> = new BehaviorSubject<{ [key:string]: ISidebarItem[] }>(null);

  private destroy$: Subject<void> = new Subject<void>();

  constructor (
    private authService: AuthService,
    private familyPortalConfig: FamilyPortalConfig,
    private windowRef: WindowRef,
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private navbarConfigService: NavbarConfigService,
  ) {}

  ngOnInit () {
    const lang = this.translateService.getActiveLang();
    this.setHtmlLangAttribute(lang);
    this.langSwitcherProps = {
      selectedLang: lang,
      languages: SUPPORTED_LANGUAGES,
    };

    // Translations for child components
    const linkAccessibilityContent : ILinkAccessibilityContent = {
      skipToMainContent: 'Skip to main content',
      skipToFooter: 'Skip to footer',
    };

    const navBarAccessibilityContent: INavBarAccessibilityContent = {
      openMenu: 'Open menu',
      closeMenu: 'Close menu',
      ecfikLogoAltText: 'Every Child and Family is Known',
    };

    const navBarConfigContent: INavBarConfigContent = {
      home: defaultNavBarContent.home,
      messages: defaultNavBarContent.messages,
      myAccount: defaultNavBarContent.myAccount,
    };

    this.translateService.translateText([
      linkAccessibilityContent.skipToMainContent,
      linkAccessibilityContent.skipToFooter,
      navBarAccessibilityContent.openMenu,
      navBarAccessibilityContent.closeMenu,
      navBarAccessibilityContent.ecfikLogoAltText,
      navBarConfigContent.home,
      navBarConfigContent.messages,
      navBarConfigContent.myAccount,
    ]).pipe(
      tap((translated) => {
        const [
          skipToMainContent,
          skipToFooter,
          openMenu,
          closeMenu,
          ecfikLogoAltText,
          home,
          messages,
          myAccount,
        ] = translated;
        this.translatedLinkAccessibility$.next({ skipToMainContent, skipToFooter });
        this.translatedNavBarAccessibility$.next({ openMenu, closeMenu, ecfikLogoAltText });
        const translatedNavBarConfig = this.navbarConfigService.getNavConfigs({ translatedContent: { home, messages, myAccount } });
        this.translatedNavBarConfig$.next(translatedNavBarConfig);
      }),
      takeUntil(this.destroy$),
    ).subscribe();
  }

  ngOnDestroy () {
    this.destroy$.next();
  }

  public logOut (): void {
    const window = this.windowRef.nativeWindow;
    this.authService.logout({
      logoutParams: {
        federated: true,
        returnTo: window.location.origin,
      },
    });
  }

  public onSwitchLanguage (lang: string) {
    this.translateService.switchLanguage(lang);
    this.setHtmlLangAttribute(lang);
  };

  private setHtmlLangAttribute (lang: string) {
    this.renderer.setAttribute(this.document.documentElement, 'lang', lang);
  }
}
