import { Injectable } from '@angular/core';

@Injectable()
export class GraphQLFamilyHelperService {
  getFamilyQuery (params: { familyId: string }): string {
    const familyId = params && params.familyId ? `"${params.familyId}"` : 'null';
    const query = `{
      family(
        familyId: ${familyId},
      ) {
          familyId
          languages
          consentStatus
          consentDate
          children {
            firstName
            lastName
            dob
            caresId
            osisId
            caringAdults {
              firstName
              lastName
              title
              phone
              email
            }
            calculatedValues {
              ACTIVE_ONLY
              GRADE
              SCHOOL_NAME
              CURR_YTD_ATT
              PAST_5_ATT
              ABSENCES_LIST_LAST_5
            }
          }
          guardians {
            firstName
            lastName
            email
            mobile
            isPrimary
            source
            guardianId
          }
        }
      }`;
    return query;
  }

  getSignedInGuardianQuery (): string {
    return `{
      currentUser {
        familyId
        guardianId
        firstName
      }
    }`;
  }

  getTranslateQuery (textArr: string[], target: string) {
    const textArrString = textArr.map(text => `"${text}"`);
    return `{
      translate(text: [${textArrString}], target: "${target}")
    }`;
  }

  getTranslationsQuery (target: string) {
    return `{
      translations(target: "${target}")
    }`;
  }
}
