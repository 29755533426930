import { TimeOutDialogModule } from './../../../shared/components/time-out-dialog/time-out-dialog.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './routing/app-routing.module';
import { AppComponent } from './app.component';
import { FamilyPortalConfig } from './services/family-portal-config';
import { HttpClientModule } from '@angular/common/http';
import { AuthModule, AuthClientConfig } from '@auth0/auth0-angular';
import { HttpInterceptorModule } from './routing/http-interceptors/http-interceptor.module';
import { NgIdleModule } from '@ng-idle/core';
import { NvLanguageSwitcherModule } from '../../../shared/nvps-libraries/design/nv-language-switcher/nv-language-switcher.module';
import { TranslateService } from './services/translate/translate.service';
import { TranslocoRootModule } from './transloco-root.module';

// Factory function to load AuthModule configuration
export function authModuleConfigFactory (
  familyPortalConfig: FamilyPortalConfig,
  config: AuthClientConfig,
) {
  const { publicConfig } = familyPortalConfig;
  return () => {
    config.set({
      domain: publicConfig.AUTH0_HOSTNAME,
      clientId: publicConfig.AUTH0_CLIENT_ID,
      authorizationParams: {
        audience: `${publicConfig.NV_API_ORIGIN}/`,
        redirect_uri: `${window.location.origin}/callback`,
      },
      useRefreshTokens: true,
      useRefreshTokensFallback: true,
      // The AuthHttpInterceptor configuration
      httpInterceptor: {
        allowedList: [
          `${publicConfig.NV_API_ORIGIN}/graphql-family-api`,
        ],
      },
    });
  };
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    AuthModule.forRoot(),
    HttpInterceptorModule.forRoot(),
    NgIdleModule.forRoot(),
    NvLanguageSwitcherModule,
    TranslocoRootModule,
    TimeOutDialogModule,
  ],
  providers: [
    FamilyPortalConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: authModuleConfigFactory,
      deps: [FamilyPortalConfig, AuthClientConfig],
      multi: true,
    },
    TranslateService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
