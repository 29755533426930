export type TLang = 'en' | 'ar' | 'bn' | 'zh-CN' | 'fr' | 'ht' | 'ko' | 'pl' | 'ru' | 'es' | 'ur';
export const SUPPORTED_LANGUAGES: { key: TLang; human: string }[] = [
  { key: 'en', human: 'English' },
  { key: 'ar', human: 'عربي' }, // Arabic
  { key: 'bn', human: 'বাংলা' }, // Bengali
  { key: 'zh-CN', human: '中文' }, // Chinese(Simplified)
  { key: 'fr', human: 'Français' }, // French
  { key: 'ht', human: 'Kreyòl' }, // Haitian Creole
  { key: 'ko', human: '한국어' }, // Korean
  { key: 'pl', human: 'Polski' }, // Polish
  { key: 'ru', human: 'Русский' }, // Russian
  { key: 'es', human: 'Español' }, // Spanish
  { key: 'ur', human: 'اُردُو' }, // Urdu
];
