import { ResponsiveLayoutModule } from './../../../../../shared/layouts/responsive-layout/responsive-layout.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FamilyPortalLayoutPageComponent } from './family-portal-layout-page.component';
import { FamilyHeaderModule } from '../../../../../shared/components/family-header/family-header.module';
import { FamilyFooterModule } from './../family-footer/family-footer.module';
import { NvLanguageSwitcherModule } from '../../../../../shared/nvps-libraries/design/nv-language-switcher/nv-language-switcher.module';
import { TranslateModule } from '../../../app/services/translate/translate.module';

@NgModule({
  declarations: [FamilyPortalLayoutPageComponent],
  imports: [
    CommonModule,
    ResponsiveLayoutModule,
    FamilyHeaderModule,
    FamilyFooterModule,
    NvLanguageSwitcherModule,
    TranslateModule,
  ],
  exports: [FamilyPortalLayoutPageComponent],
  providers: [],
  schemas: [],
})
export class FamilyPortalLayoutPageModule {}
