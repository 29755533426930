import { IDLENESS_TIME_TO_SHOW_WARNING, IDLENESS_WARNING } from './../../constants/auth.constant';
import { Title } from '@angular/platform-browser';
import { take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TimeOutDialogComponent } from '../../../../../shared/components/time-out-dialog/time-out-dialog.component';

@Injectable()
export class UserInactivity {
  private readonly defaultTitle: string;
  private dialogRef: MatDialogRef<TimeOutDialogComponent>;

  constructor (
    private authService: AuthService,
    private idle: Idle,
    private title: Title,
    public dialog: MatDialog,
  ) {
    this.defaultTitle = 'Family Portal: Every Child and Family is Known';
  }

  public start (): void {
    this.idle.setIdle(IDLENESS_TIME_TO_SHOW_WARNING);
    this.idle.setTimeout(IDLENESS_WARNING);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.title.setTitle(this.defaultTitle);
      this.reset();
    });

    this.idle.onTimeoutWarning.subscribe((countdown: number) => {
      const idleState = `You will time out in ${countdown} seconds`;
      this.openDialog();
      this.title.setTitle(idleState);
    });

    this.idle.onTimeout.pipe(
      tap(() => {
        if (this.dialogRef) this.dialogRef.close();
        this.title.setTitle(this.defaultTitle);
        this.onTimeout();
      }),
    ).subscribe();

    this.idle.watch();
  }

  private openDialog () {
    if (this.dialogRef) return;
    this.dialogRef = this.dialog.open(TimeOutDialogComponent, {
      data: {
        warningMessage: `You’ve been inactive for ${IDLENESS_TIME_TO_SHOW_WARNING / 60} minutes. For your security, your session will end in ${IDLENESS_WARNING / 60} minutes. Click “Stay logged in” to continue.`,
        logOut: this.onTimeout.bind(this),
        onNoClick: this.closeDialog.bind(this),
      },
    });

    this.dialogRef.afterClosed().pipe(
      take(1),
      tap(() => {
        this.title.setTitle(this.defaultTitle);
        this.reset();
      }),
    ).subscribe(() => {
      this.dialogRef = undefined;
    });
  }

  private closeDialog (): void {
    this.dialogRef?.close();
  }

  private reset (): void {
    this.idle.watch();
  }

  private onTimeout (): void {
    this.authService.logout({
      logoutParams: {
        federated: true,
        returnTo: window.location.origin,
      },
    });
  }
}
